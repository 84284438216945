<template>
  <div v-if="session" class="text-left">
    <vca-card class="shadowed">
      <p>
        <strong>{{ $t("profile.email.header") }}:</strong>
        {{ user.email }}
        <span class="clickable" @click="navigate('/email/edit')">
          &#9998;
        </span>
      </p>
      <p>
        <strong>{{ $t("profile.display_name") }}:</strong>
        {{ user.display_name }}
        <span class="clickable" @click="navigate('/user/edit')"> &#9998; </span>
      </p>
      <p>
        <strong>{{ $t("profile.name") }}:</strong> {{ user.full_name }}
        <span class="clickable" @click="navigate('/user/edit')"> &#9998; </span>
      </p>
      <p>
        <strong>{{ $t("profile.password") }}:</strong> *****
        <span class="clickable" @click="navigate('/password/edit')">
          &#9998;
        </span>
      </p>
      <p>{{ $t("profile.roles") }}: {{ userRoles }}</p>
    </vca-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Profile",
  computed: {
    ...mapGetters({
      user: "user/current",
      session: "session",
    }),
    userRoles() {
      return this.user.system_roles
        .map((el) => this.$t("roles.type." + el.name))
        .join(", ");
    },
  },
  methods: {
    navigate(route) {
      this.$router.push({ path: route });
    },
  },
};
</script>
