<template>
  <div class="tabs-details">
    <h1>{{ $t("profile.header") }}</h1>
    <p>{{ $t("profile.description") }}</p>
    <ProfileButtons />
  </div>
</template>

<script>
import ProfileButtons from "@/components/profile/ProfileButtons";
export default {
  name: "Profile",
  components: {
    ProfileButtons,
  },
};
</script>
